export const useTemplatesStore = defineStore('templates', {
  state: () => {
    return {
      template: null,
      templates: [],
      total: 0,
      filters: {
        type: 'page',
      },
      isLoading: false,

      page: {
        categories: [
          {
            title: useT('builder.categories'),
            items: [
              {
                id: 'all',
                title: useT('poly.all'),
                icon: 'grid-01',
              },
              {
                id: 'internal',
                title: useT('builder.category.corporate'),
                icon: 'users-02',
              },
              {
                id: 'partner',
                title: useT('builder.category.networking'),
                icon: 'building-07',
              },
              {
                id: 'gala',
                title: useT('builder.category.gala'),
                icon: 'stars-02',
              },
              {
                id: 'simple',
                title: useT('common.basic'),
                icon: 'calendar',
              },
              {
                id: 'custom',
                title: useT('builder.category.customTemplates'),
                icon: 'save-01',
              },
            ],
          },
        ],
        presets: [
          {
            id: 'page-internal-1',
            category: 'internal',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/page-64abddbd1e8bc1cf59f5a6ef.jpg?v=1688985085339',
          },
          {
            id: 'page-internal-2',
            category: 'internal',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/page-64abde9a1e8bc1cf59f5a71f.jpg?v=1688985284400',
          },
          {
            id: 'page-internal-3',
            category: 'internal',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/page-64abdee11e8bc1cf59f5a74f.jpg?v=1688985945470',
          },
          {
            id: 'page-partner-1',
            category: 'partner',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/page-64abdf211e8bc1cf59f5a77f.jpg?v=1688985412441',
          },
          {
            id: 'page-partner-2',
            category: 'partner',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/page-64abdf541e8bc1cf59f5a7af.jpg?v=1688985453777',
          },
          {
            id: 'page-gala-1',
            category: 'gala',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/page-64abdfeb1e8bc1cf59f5a7df.jpg?v=1688985596775',
          },
          {
            id: 'page-simple-1',
            category: 'simple',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/page-64abe0091e8bc1cf59f5a80e.jpg?v=1688985660202',
          },
        ],
      },

      email: {
        categories: [
          {
            title: useT('builder.categories'),
            items: [
              {
                id: 'all',
                title: useT('poly.all'),
                icon: 'grid-01',
              },
              {
                id: 'invitation',
                title: useT('builder.category.invitation'),
                icon: 'mail-05',
              },
              {
                id: 'ticket',
                title: useT('builder.category.ticket'),
                icon: 'ticket-01',
              },
              {
                id: 'calendar',
                title: useT('poly.addToCalendar'),
                icon: 'calendar-plus-02',
              },
              {
                id: 'reminder',
                title: useT('builder.category.reminder'),
                icon: 'clock',
              },
              {
                id: 'thanks',
                title: useT('builder.category.thankYou'),
                icon: 'check-heart',
              },
              {
                id: 'message',
                title: useT('builder.category.plainMessage'),
                icon: 'file-02',
              },
              {
                id: 'app',
                title: useT('event.guestApp.title'),
                icon: 'phone-01',
              },
              {
                id: 'confirmation',
                title: useT('builder.category.confirmation'),
                icon: 'check-circle-broken',
              },
              {
                id: 'custom',
                title: useT('builder.category.customTemplates'),
                icon: 'save-01',
              },
            ],
          },
        ],
        presets: [
          {
            id: 'email-calendar-1',
            category: 'calendar',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd74fd77e09a34e2f515f.jpg?v=1688983404284',
          },
          {
            id: 'email-calendar-2',
            category: 'calendar',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd779d77e09a34e2f5190.jpg?v=1688983433893',
          },
          {
            id: 'email-calendar-3',
            category: 'calendar',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd792b5050b0fe5cf0ca5.jpg?v=1688983454318',
          },
          {
            id: 'email-calendar-4',
            category: 'calendar',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd7a7b5050b0fe5cf0cd4.jpg?v=1688983474482',
          },
          {
            id: 'email-calendar-5',
            category: 'calendar',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd7bc26a605d59bad7f8b.jpg?v=1688983494710',
          },

          {
            id: 'email-invitation-1',
            category: 'invitation',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd61f26a605d59bad7f35.jpg?v=1688983088758',
          },
          {
            id: 'email-invitation-2',
            category: 'invitation',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/4bc8ad9fcafe589156c25939/template/email-658c522b975521f50c99c6ec.jpg?v=1703695220712',
          },
          {
            id: 'email-invitation-3',
            category: 'invitation',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd656b5050b0fe5cf0c1f.jpg?v=1688983135927',
          },
          {
            id: 'email-invitation-4',
            category: 'invitation',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd668b5050b0fe5cf0c52.jpg?v=1688983155654',
          },
          {
            id: 'email-invitation-5',
            category: 'invitation',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd67ed77e09a34e2f514f.jpg?v=1688983179023',
          },

          {
            id: 'email-ticket-1',
            category: 'ticket',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/4bc8ad9fcafe589156c25939/template/email-658c565f975521f50c99dca2.jpg?v=1703695984102',
          },
          {
            id: 'email-ticket-2',
            category: 'ticket',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/4bc8ad9fcafe589156c25939/template/email-658c5677975521f50c99dce8.jpg?v=1703696001729',
          },
          {
            id: 'email-ticket-3',
            category: 'ticket',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/4bc8ad9fcafe589156c25939/template/email-658c5688975521f50c99dd25.jpg?v=1703696014219',
          },
          {
            id: 'email-ticket-4',
            category: 'ticket',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/4bc8ad9fcafe589156c25939/template/email-658c5699975521f50c99dd61.jpg?v=1703696070265',
          },
          {
            id: 'email-ticket-5',
            category: 'ticket',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/4bc8ad9fcafe589156c25939/template/email-658c56cc975521f50c99dd9f.jpg?v=1703696106551',
          },

          {
            id: 'email-confirmation-1',
            category: 'confirmation',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/4bc8ad9fcafe589156c25939/template/email-658c53d6975521f50c99d342.jpg?v=1703695329401',
          },
          {
            id: 'email-confirmation-2',
            category: 'confirmation',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abda9a26a605d59bad8142.jpg?v=1688984250956',
          },
          {
            id: 'email-confirmation-3',
            category: 'confirmation',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/4bc8ad9fcafe589156c25939/template/email-658c54c2975521f50c99d38f.jpg?v=1703695569441',
          },
          {
            id: 'email-confirmation-4',
            category: 'confirmation',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/4bc8ad9fcafe589156c25939/template/email-658c54fb975521f50c99d3e2.jpg?v=1703695819065',
          },
          {
            id: 'email-confirmation-5',
            category: 'confirmation',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/4bc8ad9fcafe589156c25939/template/email-658c552d975521f50c99d74a.jpg?v=1703695832288',
          },

          {
            id: 'email-reminder-1',
            category: 'reminder',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd7d126a605d59bad7fba.jpg?v=1688983515699',
          },
          {
            id: 'email-reminder-2',
            category: 'reminder',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd7ec26a605d59bad7fe9.jpg?v=1688983546987',
          },
          {
            id: 'email-reminder-3',
            category: 'reminder',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd808b5050b0fe5cf0d09.jpg?v=1688983572808',
          },
          {
            id: 'email-reminder-4',
            category: 'reminder',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd820b5050b0fe5cf0d3b.jpg?v=1688983594685',
          },
          {
            id: 'email-reminder-5',
            category: 'reminder',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd839d77e09a34e2f51da.jpg?v=1688983637184',
          },

          {
            id: 'email-app-1',
            category: 'app',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd961d77e09a34e2f5298.jpg?v=1688983918897',
          },
          {
            id: 'email-app-2',
            category: 'app',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd97bd77e09a34e2f52c8.jpg?v=1688983960371',
          },
          {
            id: 'email-app-3',
            category: 'app',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd9ab26a605d59bad80b8.jpg?v=1688983991386',
          },
          {
            id: 'email-app-4',
            category: 'app',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd9bfd77e09a34e2f52f1.jpg?v=1688984009095',
          },
          {
            id: 'email-app-5',
            category: 'app',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abda481e8bc1cf59f5a506.jpg?v=1688984149626',
          },

          {
            id: 'email-message-1',
            category: 'message',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd8fcd77e09a34e2f5250.jpg?v=1688983814337',
          },
          {
            id: 'email-message-2',
            category: 'message',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/4bc8ad9fcafe589156c25939/template/email-658c5235975521f50c99c71b.jpg?v=1703695213070',
          },
          {
            id: 'email-message-3',
            category: 'message',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd92826a605d59bad8021.jpg?v=1688983858836',
          },
          {
            id: 'email-message-4',
            category: 'message',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd93a26a605d59bad8050.jpg?v=1688983875639',
          },
          {
            id: 'email-message-5',
            category: 'message',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd94d26a605d59bad807e.jpg?v=1688983893617',
          },

          {
            id: 'email-thanks-1',
            category: 'thanks',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd86244a8c3a966899e4d.jpg?v=1688983660207',
          },
          {
            id: 'email-thanks-2',
            category: 'thanks',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd88144a8c3a966899e80.jpg?v=1688983696769',
          },
          {
            id: 'email-thanks-3',
            category: 'thanks',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd8c044a8c3a966899ede.jpg?v=1688983754118',
          },
          {
            id: 'email-thanks-4',
            category: 'thanks',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd8d844a8c3a966899f0d.jpg?v=1688983778537',
          },
          {
            id: 'email-thanks-5',
            category: 'thanks',
            thumbnail: 'https://s3.eu-central-1.amazonaws.com/attendu/demo/events/64abd0b23f7641946ca1bb81/template/email-64abd8ead77e09a34e2f5221.jpg?v=1688983795206',
          },
        ],
      },
    };
  },

  actions: {
    async fetchTemplates() {
      this.isLoading = true;

      this.templates = [];
      this.total = 0;

      const response = await useApi.get('/templates', {
        params: {
          event: useEventStore().event?._id,
          type: this.filters.type,
        },
      }).catch(() => {
        useToast.error(useT('common.errorLoadingData'));
      });

      this.isLoading = false;

      if (response) {
        this.templates = response.results;
        this.total = response.total;
      }

      return response;
    },

    async fetchTemplate(templateId) {
      const response = await useApi.get(`/templates/${templateId}`).catch(() => {
        useToast.error(useT('common.errorLoadingData'));
        return null;
      });

      this.template = response;

      return response;
    },

    async createTemplate(body) {
      const response = await useApi.post('/templates', {
        body: {
          event: useEventStore().event?._id,
          ...body,
        },
      }).catch(() => {
        useToast.error(useT('template.creation.error'));
      });

      if (response) {
        this.fetchTemplates();
      }

      return response;
    },

    async deleteTemplate(templateId) {
      const response = await useApi.delete(`/templates/${templateId}`).catch(() => {
        useToast.error(useT('template.deletion.error'));
      });

      if (response) {
        this.fetchTemplates();
      }

      return response;
    },

    async updateTemplate({ templateId, body }) {
      const response = await useApi.patch(`/templates/${templateId}`, { body }).catch(() => {
        useToast.error(useT('common.errorSavingChanges'));
      });

      if (response) {
        this.fetchTemplates();
      }

      return response;
    },

    async duplicateTemplate(template) {
      const response = await useApi.post('/templates', {
        body: {
          event: useEventStore().event?._id,
          name: useT('builder.duplicate.Xcopy', { data: template.name }),
          type: template.type,
          thumbnail: template.thumbnail,
          params: template.params,
          sections: template.sections,
        },
      }).catch(() => {
        useToast.error(useT('template.duplication.error'));
      });

      if (response) {
        this.fetchTemplates();
      }

      return response;
    },

    async getActiveTemplate(params) {
      const response = await useApi.get('/public/templates/active-template', { params }).catch(() => null);

      return response;
    },
  },

  getters: {

  },
});
